import React, { useEffect, useState } from 'react';
import { useStaticQuery, graphql, navigate } from 'gatsby';
import { useSelector } from 'react-redux';
import Selectors from 'src/state/root-selectors';
import { map } from 'lodash';
import { Sources, Loader } from 'src/components/shared';
import { checkBetaUserPermit } from 'src/utils/featureFlag';

const PnfSourcesPage = () => {
    const [betaUser, setBetaUser] = useState(false);
    const [loadingState, setLoadingState] = useState(true);
    const {
        allContentfulSeries: { edges },
    } = useStaticQuery(graphql`
        query PhaseAndFunctionDetailSourcesQuery {
            allContentfulSeries {
                edges {
                    node {
                        contentful_id
                        ...ContentfulSeries_Card
                    }
                }
            }
        }
    `);
    const variants = useSelector(Selectors.flags.phaseAndFunction);
    const seriesId = variants?.phaseAndFunctionSeries?.variant.payload.series[0];
    const seriesData = edges?.find((e) => e.node.contentful_id === seriesId);
    const sources = seriesData?.node?.sources ?? null;

    useEffect(() => {
        if (!!variants?.phaseAndFunctionSeries) {
            if (checkBetaUserPermit(variants)) {
                setBetaUser(true);
                if (sources) {
                    setLoadingState(false);
                }
            } else {
                setLoadingState(false);
            }
        }
    }, [variants, sources]);

    if (loadingState) {
        return (
            <div className="page-section">
                <Loader />
            </div>
        );
    }

    if (!betaUser) {
        navigate('/404');
        return null;
    }

    return (
        <div className="padding-horizontal--large margin-vertical--large">
            <h2 className="bold margin--0">PHASE & FUNCTION</h2>
            <h2 className="accent margin--0">sources & additional reading</h2>
            <div className="page-section padding-top--xlarge">
                {!!sources &&
                    map(sources, (e, i) => {
                        return <Sources name={e.name} source={e.source} key={i} />;
                    })}
            </div>
        </div>
    );
};

export default PnfSourcesPage;
