import React from 'react';
import { Router } from '@reach/router';
import NotFoundPage from 'src/pages/404';
import PnfSourcesPage from 'src/components/phase-and-function/PnfSourcesPage';

const Sources = () => (
    <Router>
        <PnfSourcesPage path="/sources/phase-and-function" />
        <NotFoundPage default />
    </Router>
);

export default Sources;
